
































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { DisplayError } from "@/components/common";
import Invitations from "./components/GroupInvitations.vue";
import { GroupItem, AddGroupCard } from "./components";

@Component({
  components: {
    DisplayError,
    GroupItem,
    AddGroupCard,
    Invitations,
  },
})
export default class Groups extends Vue {
  @Getter("groups/loading") loading!: boolean;
  @Getter("groups/getGroups") groups!: Groups[];
  @Getter("groups/retrieved") retrieved!: boolean;
  @Action("groups/getGroups") getGroups!: () => Promise<void>;

  created() {
    if (!this.retrieved) this.getGroups();
  }
}
